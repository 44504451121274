import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
// * calls the profile api and sets the store
import {Dispatch} from 'react';
import {setupAxios} from '../../axiosSetup';
import {logout, profile} from '../../store/actions/account.actions';
import {apiPath} from '../../store/models/root.interface';

export const profileApi = async (
  dispatch: Dispatch<any>,
  history: any,
  location: any,
  profileData: any
) => {
  // ! overrides axios default setup and side effects are visible everywhere
  setupAxios(process.env.REACT_APP_API_BASE_URL);

  try {
    const token = localStorage.getItem('access_token') ?? '';
    let url = apiPath.getProfile;

    // ! overrides axios default setup and side effects are visible everywhere
    setupAxios(process.env.REACT_APP_API_BASE_URL, token);

    // ? don't call profile if there is already some data
    if (isEmpty(profileData)) {
      const {data: profileData} = await axios({
        method: 'post',
        url,
        data: {
          token
        }
      });

      dispatch(profile(profileData));

      // * redirect to the admin page
      history.push('/admin');
    }

    // ? redirect automatically if not
    if (!isEmpty(profile) && location?.pathname !== '/admin') {
      // * redirect to the admin page
      history.push('/admin');
    }
  } catch (error) {
    console.log(error);
    // ? redirect to login page and clear the store, only if we're not on the access page
    if (location?.pathname !== '/access' && location?.pathname !== '/login') {
      // * clear redux
      dispatch(logout());

      history.push('/login');
    }
  }
};
