import {IAccount} from './account.interface';

export interface IRootPageStateType {
  area: string;
  subArea: string;
}
export interface IRootPagination {
  totalPages: number;
  currentPage: number;
}

export interface IRootSnackbarStateType {
  successSnackbarOpen: boolean;
  successSnackbarMessage: string;
  severity: string;
}
export interface IRequest {
  uuid: string;
  name?: string;
  status?: string;
  createdAt: string;
  notes?: string;
  servicequeueuuid?: string;
  serviceQueueUUID: string;
  serviceQueue: {
    name: string;
  };
  isautomated: boolean;
  foodSRType: string;
  options?: string[];
}

export interface IRootStateType {
  page: IRootPageStateType;
  pagination: IRootPagination;
  loading: boolean;
  snackbar: IRootSnackbarStateType;
  requests: [];
  queues: [];
  userData: {};
  account: {};
}
export interface IStateType {
  root: IRootStateType;
  account: IAccount;
}
export interface IActionBase {
  type: string;
  [prop: string]: any;
}

export interface IRequestState {
  requests: IRequest[];
}
export interface IQueue {
  uuid?: string;
  name?: string;
  updatedAt?: string;
  createdAt?: string;
}

export enum Status {
  'Registered' = 'Registered',
  'Checked In' = 'Checked In',
  'Checked Out' = 'Checked Out'
}

export interface IStudent {
  uuid?: string;
  updatedAt?: string;
  createdAt?: string;
  isSelfCheckoutAuthorized?: boolean;
  scheduledMoveOutAt?: string;
  isOffCampus?: boolean;
  needsDiningServices?: boolean;
  individualUUID?: string;
  bed?: {
    roomUUID?: string;
  };
  allergies?: string;
  foodPreferences?: string;
  status?: Status;
}

export interface IQueueState {
  requests: IQueue[];
}

export const apiPath = {
  requestList: '/service-requests',
  requestCount: '/service-requests/count',
  servieQueueList: '/service-queues',
  postRequets: '/service-requests',
  putIndividual: '/individuals',
  checkout: '/student/checkout',
  getloggedInUserData: '/cases',
  getProfile: '/profile'
};

export const settings = {
  defaultPageSize: 20,
  requestStatus: {
    Requested: 'Requested',
    Completed: 'Completed',
    'In Progress': 'In Progress',
    Cancelled: 'Cancelled',
    'Ready for Pickup': 'Ready for Pickup'
    // 'Being Delivered': 'Being Delivered',
    // Delivered: 'Delivered'
  },
  google_stay_link:
    'https://sites.google.com/brown.edu/student-resources-for-isolatio/home',
  logout_url: `${process.env.REACT_APP_API_BASE_URL}/logout`
};

export const defaultRequestStatus = 'Requested';
