import React, {FunctionComponent} from 'react';
import './styles/main.scss';
import {Redirect, Route} from 'react-router';
import {BrowserRouter as Router, Switch} from 'react-router-dom';

import Login from './components/Login/Login';
import Access from './components/Access/Access';
import Admin from './components/Admin/Admin';

import SuccessSnackbar from './common/components/Snackbar';

const App: FunctionComponent = () => {
  return (
    <div className="page-container">
      <SuccessSnackbar />
      <Router basename="/individual-request-console/">
        <Switch>
          <Route path="/access">
            <Access />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          {/* default route as login */}
          <Redirect
            to={{
              pathname: '/login',
              state: {from: '/'}
            }}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
