import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement<any, any>},
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  agreeClickHandler: any;
  cancelClickHandler: any;
  isOpen: boolean;
  title: string;
  message: string;
}

export default function CancelDialog(props: IProps) {
  const title = props.title || 'Please confirm ?';
  const message = props.message || '';

  return (
    <div>
      <Dialog
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.cancelClickHandler}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="dialog-controls controls">
            <Button
              onClick={props.cancelClickHandler}
              className="btn-b btn-sm btn-primary btn-outlined"
            >
              No, do not cancel
            </Button>
            <Button
              onClick={props.agreeClickHandler}
              color="primary"
              className="btn-b btn-sm btn-primary btn-filled"
            >
              Yes, cancel this request
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
