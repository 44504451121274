import {createStore, applyMiddleware, Store} from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/root.reducer';
import {composeWithDevTools} from 'redux-devtools-extension';

const configureStore = (): Store => {
  const middlewares = [applyMiddleware(thunkMiddleware)];

  const composedEnhancers = composeWithDevTools(...middlewares);
  return createStore(rootReducer, {} as any, composedEnhancers);
};

export default configureStore;
