import React, {Dispatch} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import {IStateType} from '../../store/models/root.interface';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {clearSnackbar} from '../../store/actions/root.actions';
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

export default function SuccessSnackbar() {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();

  const vertical = 'top';
  const horizontal = 'right';
  const successSnackbarMessage: string = useSelector(
    (state: IStateType) => state.root.snackbar.successSnackbarMessage
  );
  const successSnackbarOpen: boolean = useSelector(
    (state: IStateType) => state.root.snackbar.successSnackbarOpen
  );
  const severity: any = useSelector(
    (state: IStateType) => state.root.snackbar.severity
  );
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearSnackbar());
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{vertical, horizontal}}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={severity || 'success'}>
          {successSnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
