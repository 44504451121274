import React, {useState, Dispatch} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import './Dashboard.scss';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Typography
} from '@material-ui/core';
import {
  IStateType,
  IRequest,
  IQueue,
  Status,
  IStudent
} from '../../store/models/root.interface';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import {
  getFormatedDateTime,
  getQueueNameById
} from '../../common/helpers/helpers';
import Loader from '../../common/components/Loader';
import CancelDialog from './CancelDialog';
import {
  snackbarSuccess,
  updateLoader,
  postRequest,
  getRequestList
} from '../../store/actions/root.actions';
import {apiPath} from '../../store/models/root.interface';
import {requestFormDefaultValues} from '../../store/models/requests';

export type RequestsListProps = {
  children?: React.ReactNode;
  handleSortClick: any;
  queues: IQueue[];
  userData: any;
};

function RequestListing(props: RequestsListProps): JSX.Element {
  const dispatch: Dispatch<any> = useDispatch();
  const requests: IRequest[] = useSelector(
    (state: IStateType) => state.root.requests
  );
  const [sortBy, setSortBy] = useState('uuid');
  const [sortAscOrder, setSortAscOrder] = useState('DESC');
  const queues = props.queues || [];
  const userData: IStudent = useSelector(
    (state: IStateType) => state.root.userData
  );
  const [isCancelOpen, setIsCancelOpen] = React.useState(false);
  const loading: boolean = useSelector(
    (state: IStateType) => state.root.loading
  );
  const [actionUUID, setActionUUID] = React.useState('');

  const updateStatus = async (status: string) => {
    const uuid = actionUUID || '';
    const formInput = {
      status
    };
    const fr = await postRequest(
      formInput,
      `${apiPath.requestList}/${uuid}`,
      'PATCH'
    );
    dispatch(updateLoader(false));

    if (fr.status === 200 || fr.status === 204) {
      dispatch(getRequestList(1, requestFormDefaultValues));
      dispatch(snackbarSuccess('success', 'Request updated successfully !'));
    } else {
      const response = await fr.response.json();
      dispatch(
        snackbarSuccess(
          'warning',
          response.error.message ||
            'Something went wrong while submit form, please try again !'
        )
      );
    }
  };
  const agreeClickHandler = async () => {
    dispatch(updateLoader(true));
    setIsCancelOpen(false);
    updateStatus('Cancelled');
  };

  const cancelClickHandler = async () => {
    setIsCancelOpen(false);
  };

  const tableHeaders = {
    name: 'Request name',
    createdAt: 'Request date',
    servicequeueuuid: 'Service queue name',
    status: 'Request status'
  };

  const handleSortBy = (
    event: React.ChangeEvent<unknown>,
    value: string,
    sortOrder: string
  ) => {
    let sortAscDesc: string = sortOrder === 'DESC' ? 'ASC' : 'DESC';
    setSortBy(value);
    setSortAscOrder(sortAscDesc);
    props.handleSortClick(value, sortAscDesc);
  };

  const handleCancelClick = (uuid: string) => {
    setActionUUID(uuid);
    setIsCancelOpen(true);
  };

  const tableElements: (JSX.Element | null)[] =
    requests &&
    requests.map(request => {
      if (!request) {
        return null;
      }
      return (
        <tr className={`table-row `} key={`request_${request.uuid}`}>
          <td data-label="Task name">
            <Accordion>
              <AccordionSummary aria-controls="panel1a-content">
                {(request &&
                  request.name?.replace('Facilities :', 'Maintenance :')) ||
                  ''}
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  style={{
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  {request.foodSRType === '7 day Meal Pack' && (
                    <>
                      <span>
                        Options: {(request.options ?? ['N/A']).join(', ')}
                      </span>
                      <br />
                    </>
                  )}
                  Notes: {request?.notes || 'N/A'}
                  <br />
                  {request &&
                    getQueueNameById(queues, request.serviceQueueUUID) ===
                      'Dining' && (
                      <>
                        Dietary Preference:{' '}
                        {(props.userData && props.userData.foodPreferences) ||
                          'N/A'}
                        <br />
                        Allergy Information:{' '}
                        {(props.userData && props.userData.allergies) || 'N/A'}
                      </>
                    )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </td>
          <td data-label="Date assigned">
            {(request &&
              getFormatedDateTime(request.createdAt, 'MM-DD-YYYY hh:mm A')) ||
              'N/A'}
          </td>
          <td data-label="service queue name">
            {(request && getQueueNameById(queues, request.serviceQueueUUID)) ||
              'N/A'}
          </td>
          <td data-label="status">{(request && request.status) || 'N/A'}</td>
          <td data-label="">
            {(request && getQueueNameById(queues, request.serviceQueueUUID)) !==
              'Pharmacy' && (
              <div>
                {request.status === 'Requested' &&
                  !request.isautomated &&
                  // add a condition for a disabled component
                  (() => {
                    if (userData?.status !== Status['Checked In']) {
                      return <Link color="primary">Cancel</Link>;
                    } else {
                      return (
                        <Link
                          color="primary"
                          onClick={() =>
                            handleCancelClick(request.uuid as string)
                          }
                        >
                          Cancel
                        </Link>
                      );
                    }
                  })()}
              </div>
            )}
          </td>
        </tr>
      );
    });

  return (
    <div className="table-responsive portlet">
      <Loader isLoading={loading} />
      <CancelDialog
        title="Cancel Request"
        message="Are you sure you wish to cancel this request? If you cancel in error you will need to create a new request."
        agreeClickHandler={agreeClickHandler}
        isOpen={isCancelOpen}
        cancelClickHandler={cancelClickHandler}
      />
      <table className="table-default m-t-md">
        <thead>
          <tr>
            {tableHeaders &&
              Object.entries(tableHeaders).map(([key, value]) => {
                return (
                  <th
                    key={key}
                    className={
                      sortBy === key
                        ? 'cursor-pointer active'
                        : 'cursor-pointer'
                    }
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      handleSortBy(
                        (event.target as any).index,
                        key as string,
                        sortAscOrder as string
                      );
                    }}
                  >
                    <div className="heading-sort">
                      {value}
                      <span className="arrowactive">
                        <i className={'fa fa-sort fa fa-caret-up'}></i>
                      </span>
                      {sortBy === key && sortAscOrder === 'ASC' && (
                        <ArrowUpwardIcon className="arrow" fontSize="small" />
                      )}
                      {sortBy === key && sortAscOrder === 'DESC' && (
                        <ArrowDownward className="arrow" fontSize="small" />
                      )}
                      {sortBy !== key && sortAscOrder === 'DESC' && (
                        <UnfoldMoreIcon className="arrow" fontSize="small" />
                      )}
                    </div>
                  </th>
                );
              })}

            <th className="center">Action</th>
          </tr>
        </thead>
        <tbody>
          {tableElements && tableElements.length > 0 ? (
            tableElements
          ) : (
            <tr className={`table-row center`}>
              <td className="name" data-label="Task name" colSpan={5}>
                No requests found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default RequestListing;
