import React, {useState, ChangeEvent} from 'react';
import TextField from '@material-ui/core/TextField';
import {TextInputProps} from '../types/TextInput.types';

function TextInput(props: TextInputProps): JSX.Element {
  const [touched, setTouch] = useState(false);
  const [error, setError] = useState('');
  const [, setValue] = useState('');

  function onValueChanged(event: ChangeEvent<HTMLInputElement>): void {
    let [error, elementValue] = ['', event.target.value];
    [error] =
      !elementValue && props.required
        ? [
            'Please enter N/A or any further details necessary to fulfill your request.'
          ]
        : [''];

    if (
      (props.field === 'password' || props.field === 'newPassword') &&
      elementValue
    ) {
      const re = new RegExp(
        '^(?=.*[0123456789])(?=.*[a-z])(?=.*[A-Z]).{6,10}$'
      );
      [error] = !re.test(elementValue)
        ? [
            `Password must contain at least one upper case letter, one lower case letter, and one numeric digit and should be 6 to 10 char long`
          ]
        : [''];
    }

    props.onChange({
      value: elementValue,
      error: error,
      touched: touched,
      field: props.field
    });

    setTouch(true);
    setError(error);
    setValue(elementValue);
  }

  return (
    <div>
      <label htmlFor={props.id.toString()}>
        {props.label} {props.required && <span style={{color: 'red'}}>*</span>}
      </label>

      {props.required && (
        <TextField
          multiline
          error={error ? true : false}
          rowsMax={6}
          value={props.value}
          disabled={props.disabled}
          onChange={onValueChanged}
          id={`id_${props.label}`}
          placeholder={props.placeholder}
          variant="outlined"
        />
      )}
      {!props.required && (
        <TextField
          // label={props.label}
          multiline
          error={false}
          rowsMax={4}
          value={props.value}
          disabled={props.disabled}
          onChange={onValueChanged}
          id={`id_${props.label}`}
          placeholder={props.placeholder}
          variant="outlined"
        />
      )}
      {props.required && error ? (
        <div className="invalid-feedback">{error}</div>
      ) : null}
    </div>
  );
}

export default TextInput;
