import React, {FormEvent, Dispatch} from 'react';
import {useDispatch} from 'react-redux';
import './Popup.scss';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  TextField
} from '@material-ui/core';
import {IPopupProps} from '../../store/models/requests';
import {
  snackbarSuccess,
  postRequest,
  getQueueIdValue,
  updateLoader
  // getRequestNameByNotes
} from '../../store/actions/root.actions';
import {apiPath, defaultRequestStatus} from '../../store/models/root.interface';

const foodOptions = {
  'standard 7 day meal pack': {
    label: 'Standard 7 day meal pack',
    description:
      'Includes: assorted fresh fruit, coffee, tea, milk, salty snacks, mac & cheese, hot pockets, instant ramen, and uncrustables'
  },
  oatmeal: {label: 'Oatmeal', description: ''},
  cereal: {label: 'Cereal', description: ''},
  bars: {label: 'Granola Bars', description: ''},
  'extra milk': {label: 'Extra milk', description: ''},
  'extra mac & cheese': {label: 'Extra mac & cheese', description: ''},
  'extra hot pockets': {label: 'Extra hot pockets', description: ''}
};
type FoodOptions = Record<keyof typeof foodOptions, boolean>;
type FoodOptionKey = keyof FoodOptions;
const foodOptionKeys = Object.keys(foodOptions) as FoodOptionKey[];

function getDefaultFoodOptions() {
  return foodOptionKeys.reduce<FoodOptions>((memo, option) => {
    memo[option] = option === 'standard 7 day meal pack';
    return memo;
  }, {} as FoodOptions);
}

function getFoodRequestNameFromOptions(options: FoodOptions) {
  const selectedOptions = foodOptionKeys
    .reduce<string[]>((memo, option) => {
      if (!options[option]) {
        return memo;
      }

      return [...memo, foodOptions[option].label];
    }, [])
    .join(', ');
  return selectedOptions.length > 0
    ? `7 day Meal Pack: ${selectedOptions}`
    : '7 day Meal Pack';
}

export default function FoodRequestModal(props: IPopupProps) {
  const dispatch: Dispatch<any> = useDispatch();
  const defaultFormVals = {notes: '', options: getDefaultFoodOptions()};
  const queues = props.queues || [];
  const [formState, setFormState] = React.useState({
    notes: {error: '', value: defaultFormVals.notes},
    options: {error: '', value: defaultFormVals.options}
  });
  const [isSubmitClicked, setSubmitClicked] = React.useState(false);

  function onNotesChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setFormState({
      ...formState,
      [event.target.name]: {error: '', value: event.target.value}
    });
  }

  function isFormValid(): boolean {
    const isAnOptionSelected = !!foodOptionKeys.find(
      option => formState.options.value[option]
    );
    const areNotesProvided =
      !formState.notes.error && formState.notes.value !== '';
    return isAnOptionSelected || areNotesProvided;
  }

  async function saveForm(e: FormEvent<HTMLFormElement>): Promise<boolean> {
    e.preventDefault();
    setSubmitClicked(true);
    if (!isFormValid()) {
      dispatch(
        snackbarSuccess('warning', 'Please check all required fields !')
      );
      return false;
    }
    dispatch(updateLoader(true));
    const queueUUId = getQueueIdValue(queues, 'Dining');
    let formInput: any = {};
    const roomUUID = props.userData.bed?.roomUUID || null;
    formInput = {
      notes: formState.notes.value || '',
      // name: getRequestNameByNotes(formState.notes.value),
      name: getFoodRequestNameFromOptions(formState.options.value),
      serviceQueueUUID: queueUUId,
      caseUUID: props.userData.uuid || null,
      foodSRType: '7 day Meal Pack',
      status: defaultRequestStatus
    };
    if (!props.userData.isOffCampus && roomUUID !== null) {
      formInput = {...formInput, roomUUID: roomUUID};
    }
    try {
      const fr = await postRequest(formInput, apiPath.postRequets, 'POST');
      const response = await fr.response.json();
      dispatch(updateLoader(false));
      props.onClose();
      dispatch(
        snackbarSuccess(
          fr.status === 200 ? 'success' : 'warning',
          fr.status === 200
            ? 'Request submitted successfully'
            : response.error.message ||
                'Something went wrong while submit form, please try again !'
        )
      );
      return true;
    } catch (error) {
      console.log(error);
      snackbarSuccess(
        'warning',
        'Something went wrong while submit form, please try again !'
      );
      return true;
    }
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newOptions = {
      ...formState.options.value,
      [event.target.name]: event.target.checked
    };
    setFormState({...formState, options: {error: '', value: newOptions}});
  }

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">FOOD REQUEST</DialogTitle>
        <DialogContent>
          <form onSubmit={saveForm}>
            <div>
              <div className="p-b">
                <FormControl>
                  <FormLabel component="legend">
                    Dietary Preference:{' '}
                    {(props.userData && props.userData.foodPreferences) ||
                      'N/A'}
                  </FormLabel>
                </FormControl>
              </div>
              <div className="p-b">
                <FormControl>
                  <FormLabel component="legend">
                    Allergy Information:{' '}
                    {(props.userData && props.userData.allergies) || 'N/A'}
                  </FormLabel>
                </FormControl>
              </div>
            </div>

            <div>
              {foodOptionKeys.map(option => {
                return (
                  <div key={option}>
                    <FormControlLabel
                      value={option}
                      label={foodOptions[option].label}
                      control={
                        <Checkbox
                          name={option}
                          onChange={handleCheckboxChange}
                          checked={formState.options.value[option]}
                        />
                      }
                    />
                    {foodOptions[option].description && (
                      <p
                        style={{
                          fontSize: '.8rem',
                          color: 'gray',
                          marginTop: '-.5rem',
                          marginBottom: 0,
                          marginLeft: '33px'
                        }}
                      >
                        {foodOptions[option].description}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>
                  Please provide details: <span style={{color: 'red'}}>*</span>
                </label>
                <TextField
                  multiline
                  name="notes"
                  error={
                    isSubmitClicked &&
                    (formState.notes.value === '' ||
                      formState.notes.value.length < 3)
                  }
                  rowsMax={6}
                  value={formState.notes.value}
                  onChange={onNotesChange}
                  id={`id_notes`}
                  placeholder="Further information about this request"
                  variant="outlined"
                />

                {isSubmitClicked &&
                  (formState.notes.value === '' ||
                    formState.notes.value.length < 3) && (
                    <div className="invalid-feedback">
                      {
                        'Please enter N/A or any further details necessary to fulfill your request.'
                      }
                    </div>
                  )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                Please note: orders placed after 3pm may not be fulfilled until
                the next day.
              </div>
            </div>
            <div className="form-row">
              <div className="form-group request-food-btn">
                <Button
                  type="submit"
                  // disabled={isFormInvalid()}
                  className={`btn-b btn-primary btn-filled`}
                >
                  Request
                </Button>
                <Button
                  className="btn-b btn-primary btn-outlined"
                  onClick={() => props.onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
