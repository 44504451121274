import moment from 'moment';
import _ from 'lodash';
import {IQueue} from '../../store/models/root.interface';

export const getFormatedDateTime = (date: string, dateFormat: string) => {
  return moment(date).format(dateFormat);
};

export const getQueueNameById = (queues: IQueue[], uuid: string) => {
  var queueData = _.find(queues, {uuid: uuid});
  const name: any = (queueData && queueData.name) || '';
  return name;
};
