import React, {Fragment, Dispatch, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {Select, FormControl, MenuItem, InputLabel} from '@material-ui/core';
import './Dashboard.scss';
import FoodRequestModal from './FoodRequestModal';
import NonMedicalRequestModal from './NonMedicalRequestModal';
import CleaningRequestModal from './CleaningRequestModal';
import SupplyRequestModal from './SupplyRequestModal';
import CheckoutModal from './CheckoutModal';
import Link from '@material-ui/core/Link';
import {
  getRequestList,
  getIndividualData,
  getServiceQueueList
} from '../../store/actions/root.actions';
import {
  IStateType,
  IQueue,
  IStudent,
  Status
} from '../../store/models/root.interface';
import {requestFormDefaultValues} from '../../store/models/requests';
import RequestListing from './RequestListing';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import {settings} from '../../store/models/root.interface';
import {selectProfile} from '../../store/reducers/root.reducer';

const usePaginationStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(2)
      }
    }
  })
);

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    checkoutCard: {
      marginTop: theme.spacing(6)
    },
    checkoutButton: {
      marginTop: theme.spacing(4)
    },
    root: {
      '& > * + *': {
        marginLeft: theme.spacing(2)
      }
    }
  })
);

function studentCanCheckout(student: IStudent) {
  const now = new Date();
  const checkoutTime = student.scheduledMoveOutAt
    ? new Date(student.scheduledMoveOutAt)
    : now;
  const checkoutTimeHasPassed = now.getTime() - checkoutTime.getTime() > 0;
  return (
    student.isSelfCheckoutAuthorized &&
    checkoutTimeHasPassed &&
    student.status === 'Checked In'
  );
}

const Dashboard: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const queues: IQueue[] = useSelector(
    (state: IStateType) => state.root.queues
  );
  const userData: IStudent = useSelector(
    (state: IStateType) => state.root.userData
  );
  const paginationClasses = usePaginationStyles();
  const classes = useStyles();
  const profile = useSelector(selectProfile);

  const [isOpenRequestFood, setOpenRequestFoodState] = React.useState(false);
  const [isOpenRequestNonMedical, setRequestNonMedical] = React.useState(false);
  const [isOpenRequestCleaning, setRequestCLeaning] = React.useState(false);
  const [isOpenRequestSupply, setRequestSupply] = React.useState(false);
  const [isOpenCheckout, setCheckout] = React.useState(false);
  const totalPages: number = useSelector(
    (state: IStateType) => state.root.pagination.totalPages
  );
  const [searchState, setSearchState] = React.useState(
    requestFormDefaultValues
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const requestStatus: any = settings.requestStatus || [];

  const setOpenRequestFood = () => {
    setOpenRequestFoodState(false);
    dispatch(getRequestList(1, searchState));
  };

  const changeRequestFacility = () => {
    setRequestNonMedical(false);
    dispatch(getRequestList(1, searchState));
  };

  const changeRequestCleaning = () => {
    setRequestCLeaning(false);
    dispatch(getRequestList(1, searchState));
  };

  const changeRequestSupply = () => {
    setRequestSupply(false);
    dispatch(getRequestList(1, searchState));
  };
  const closeCheckout = () => {
    setCheckout(false);
    dispatch(getRequestList(1, searchState));
  };

  const handlePageClick = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    dispatch(getRequestList(value, searchState));
  };

  const handleSortClick = (sortBy: string, sortOrder: string) => {
    let formState = searchState;
    formState.sortBy = sortBy;
    formState.sortOrder = sortOrder;
    setSearchState({
      ...searchState,
      sortBy: sortBy as string,
      sortOrder: sortOrder as string
    });
    dispatch(getRequestList(currentPage, formState));
  };

  useEffect(() => {
    dispatch(getRequestList(1, searchState));
    dispatch(getServiceQueueList());
    // ? profile
    if (profile?.user?.emailAddress) {
      dispatch(getIndividualData(profile?.user?.emailAddress));
    }
  }, [dispatch, profile, searchState]);

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSearchState({...searchState, status: event.target.value as string});
    dispatch(
      getRequestList(currentPage, {
        ...searchState,
        status: event.target.value as string
      })
    );
  };
  const handleNameChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSearchState({...searchState, name: event.target.value as string});
    dispatch(
      getRequestList(currentPage, {
        ...searchState,
        name: event.target.value as string
      })
    );
  };

  return (
    <Fragment>
      {isOpenRequestFood && (
        <FoodRequestModal
          userData={userData}
          queues={queues}
          isOpen={isOpenRequestFood}
          onClose={setOpenRequestFood}
        />
      )}
      {isOpenRequestNonMedical && (
        <NonMedicalRequestModal
          userData={userData}
          queues={queues}
          isOpen={isOpenRequestNonMedical}
          onClose={changeRequestFacility}
        />
      )}

      {isOpenRequestCleaning && (
        <CleaningRequestModal
          userData={userData}
          queues={queues}
          isOpen={isOpenRequestCleaning}
          onClose={changeRequestCleaning}
        />
      )}
      {isOpenRequestSupply && (
        <SupplyRequestModal
          userData={userData}
          queues={queues}
          isOpen={isOpenRequestSupply}
          onClose={changeRequestSupply}
        />
      )}
      {isOpenCheckout && (
        <CheckoutModal
          userData={userData}
          queues={queues}
          isOpen={isOpenCheckout}
          onClose={closeCheckout}
        />
      )}

      <div className="sidebar-layout">
        <div className="page-content" id="main-content">
          <div className="Dashboard">
            <div className="m-b-lg xs-only ">
              <Link
                href={settings.google_stay_link}
                color="inherit"
                target="_blank"
                rel="noopener"
                className="card info-btn"
              >
                <div className="txt">View information for your stay</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="#333333">
                    <path
                      fill="#333333"
                      d="M22.707,11.293L15,3.586L13.586,5l6,6H2c-0.553,0-1,0.448-1,1s0.447,1,1,1h17.586l-6,6L15,20.414 l7.707-7.707C23.098,12.316,23.098,11.684,22.707,11.293z"
                    />
                  </g>
                </svg>
              </Link>
            </div>
            <h1 className="page-title">
              <span className="name">Request Management</span>
              <svg aria-hidden="true" xmlnsXlink="http://www.w3.org/1999/xlink">
                <use xlinkHref="images/ui.svg#dashboard"></use>
              </svg>
            </h1>

            <section className="collapsible expanded" id="tasksGroup">
              <div
                className="content"
                id="tasksSection"
                role="region"
                aria-labelledby="tasksToggle"
              >
                <div className="card">
                  {!isEmpty(userData?.status) && (
                    <div className="row btn-group">
                      {userData && !userData?.isOffCampus && (
                        <>
                          <div className="col-auto">
                            <button
                              disabled={
                                userData?.status !== Status['Checked In']
                              }
                              onClick={() => {
                                setRequestCLeaning(true);
                              }}
                              className="btn-b btn-sm btn-primary btn-filled"
                            >
                              Maintenance Request
                            </button>
                          </div>
                          <div className="col-auto">
                            <button
                              disabled={
                                userData?.status !== Status['Checked In']
                              }
                              onClick={() => {
                                setRequestSupply(true);
                              }}
                              className="btn-b btn-sm btn-primary btn-filled"
                            >
                              Supply Request
                            </button>
                          </div>
                        </>
                      )}

                      <div className="col-auto">
                        <button
                          disabled={userData?.status !== Status['Checked In']}
                          onClick={() => {
                            setRequestNonMedical(true);
                          }}
                          className="btn-b btn-sm btn-primary btn-filled"
                        >
                          Non-Medical Request
                        </button>
                      </div>
                    </div>
                  )}
                  {userData && studentCanCheckout(userData) && (
                    <div className={classes.checkoutCard}>
                      <div className="checkout d-sm-flex justify-content-between align-items-center">
                        <div className="p-r-md">
                          <div className="intro">Check out initiated</div>
                          <div className="description">
                            Please click the check-out button to complete the
                            checkout process.
                          </div>
                        </div>

                        <button
                          onClick={() => {
                            setCheckout(true);
                          }}
                          className="btn-b btn-primary btn-filled"
                        >
                          Checkout
                        </button>
                      </div>
                      {/* <Card variant="outlined">
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Typography
                                color="textSecondary"
                                variant="h5"
                                component="h2"
                                gutterBottom
                              >
                                Check out initiated
                              </Typography>
                              <Typography variant="h5" component="h4">
                                Please click the check-out button to complete
                                checkout process
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              &nbsp;
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className={classes.checkoutButton}>

                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card> */}
                    </div>
                  )}

                  <div className="card">
                    <div className="card-header d-sm-flex justify-content-between p-b-md">
                      <h2 className="card-title">My request history</h2>
                    </div>
                    <div className="card-content">
                      <form noValidate autoComplete="off">
                        <div className="row">
                          <div className="col-sm-3">
                            <FormControl
                              variant="outlined"
                              fullWidth
                              className={classes.formControl}
                              size="small"
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Request Type
                              </InputLabel>
                              <Select
                                labelId="searchQueue-label"
                                id="searchQueue"
                                value={searchState.name}
                                onChange={handleNameChange}
                                label="Request Type"
                              >
                                <MenuItem value="All">All</MenuItem>
                                {queues &&
                                  Object.entries(queues).map(([key, value]) => {
                                    return (
                                      <MenuItem
                                        key={value.uuid}
                                        value={value.uuid}
                                      >
                                        {value.name !== 'Facilities'
                                          ? value.name
                                          : 'Maintenance'}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-sm-3">
                            <FormControl
                              variant="outlined"
                              fullWidth
                              className={classes.formControl}
                              size="small"
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Request Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={searchState.status}
                                onChange={handleChange}
                                label="Request Status"
                              >
                                <MenuItem value="All">All</MenuItem>
                                {requestStatus &&
                                  Object.entries(requestStatus).map(
                                    ([key, value]) => {
                                      return (
                                        <MenuItem key={key} value={key}>
                                          {key}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </form>
                    </div>
                    <RequestListing
                      userData={userData}
                      handleSortClick={handleSortClick}
                      queues={queues}
                    />
                    {totalPages > 1 && (
                      <div className={paginationClasses.root}>
                        <Pagination
                          count={totalPages}
                          variant="outlined"
                          shape="rounded"
                          page={currentPage}
                          onChange={handlePageClick}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
