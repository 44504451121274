import React from 'react';
import logo from '../../images/logo.svg';
import Link from '@material-ui/core/Link';
import {settings} from '../../store/models/root.interface';
import {useDispatch, useSelector} from 'react-redux';
import {selectProfile} from '../../store/reducers/root.reducer';
import isEmpty from 'lodash/isEmpty';
import {logout} from '../../store/actions/account.actions';
import {useHistory, useLocation} from 'react-router';

const Header: React.FC = () => {
  const profile = useSelector(selectProfile);
  const isProfile = !isEmpty(profile);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleLogout = () => {
    // * clear redux
    dispatch(logout());

    history.push('/login');

    // * redirect it to our backend logout for saml logout
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/logout`;
  };

  return (
    <>
      <header className="site-header">
        <div className="logo-lockup">
          <img className="logo" src={logo} alt="Brown University - Logo" />
          <div className="lockup">Temporary Resource Management (TRM)</div>
        </div>
        {/* for logged in users */}
        {isProfile && location?.pathname !== '/access' && (
          <>
            <nav className="toolbar-nav hidden-xs">
              <button className="btn-b btn-sm btn-primary btn-filled">
                <Link
                  href={settings.google_stay_link}
                  target="_blank"
                  rel="noopener"
                  style={{color: 'white', textDecoration: 'none'}}
                >
                  Information for your stay
                </Link>
              </button>
            </nav>
            <div className="user">
              <div className="dropdown">
                <div className="person">
                  <div className="name hidden-xs">
                    {profile?.user?.emailAddress}
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="log-out" onClick={handleLogout}>
                    Log out
                  </a>
                </div>
                <svg
                  className="hidden-xs"
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                >
                  <circle fill="#BADEFC" cx="24" cy="24" r="23" />{' '}
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#335262"
                    d="M38.2770386,35.2519531L29,31v-7.2608643H19V31 l-9.2770386,4.2519531c-1.5061035,0.6902466-2.7225342,2.5869141-2.7229004,4.2388306C11.2064819,44.1043701,17.2649536,47,24,47 s12.7935181-2.8956299,16.999939-7.5092163C40.9995728,37.8412476,39.7807007,35.9411011,38.2770386,35.2519531z"
                  />{' '}
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#5A7A84"
                    d="M24,29c-4.4182796,0-8-3.5817204-8-8v-3 c0-4.4182816,3.5817204-8,8-8s8,3.5817184,8,8v3C32,25.4182796,28.4182796,29,24,29z"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
        {/* for users on access page */}
        {location?.pathname === '/access' && (
          <button
            className="btn-b btn-sm btn-primary btn-filled"
            onClick={handleLogout}
          >
            Log out
          </button>
        )}
      </header>
    </>
  );
};

export default Header;
