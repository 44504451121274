import React, {FormEvent, Dispatch} from 'react';
import {useDispatch} from 'react-redux';
import './Popup.scss';
import {TextField} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {IPopupProps, INonMedical} from '../../store/models/requests';

import {
  snackbarSuccess,
  postRequest,
  getQueueIdValue,
  updateLoader,
  getRequestNameByNotes
} from '../../store/actions/root.actions';
import {apiPath} from '../../store/models/root.interface';

export default function FacilityRequestModal(props: IPopupProps) {
  const dispatch: Dispatch<any> = useDispatch();
  const defaultFormVals = {notes: ''};
  let inputForm: INonMedical | null = defaultFormVals;
  const queues = props.queues || [];
  const [formState, setFormState] = React.useState({
    name: {value: 'Non Medical Request'},
    notes: {error: '', value: inputForm.notes}
  });

  const [isSubmitClicked, setSubmitClicked] = React.useState(false);

  function hasFormValueChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    setFormState({
      ...formState,
      [event.target.name]: {error: '', value: event.target.value}
    });
  }

  function isFormInvalid(): boolean {
    return (formState.notes.error || formState.notes.value === '') as boolean;
  }

  async function saveForm(e: FormEvent<HTMLFormElement>): Promise<boolean> {
    e.preventDefault();
    setSubmitClicked(true);
    if (isFormInvalid()) {
      dispatch(
        snackbarSuccess('warning', 'Please check all required fields !')
      );
      return false;
    }
    dispatch(updateLoader(true));
    const queueUUId = getQueueIdValue(queues, 'Non Medical Request');
    let formInput: any = {};
    const roomUUID = props.userData.bed?.roomUUID || null;
    formInput = {
      name: `Non Medical Request : ${getRequestNameByNotes(
        formState.notes.value
      )}`,
      notes: formState.notes.value || '',
      serviceQueueUUID: queueUUId,
      caseUUID: props.userData.uuid || null,
      status: 'Requested'
    };
    if (!props.userData.isOffCampus && roomUUID !== null) {
      formInput = {...formInput, roomUUID: roomUUID};
    }
    try {
      const fr = await postRequest(formInput, apiPath.postRequets, 'POST');
      const response = await fr.response.json();
      dispatch(updateLoader(false));
      props.onClose();
      dispatch(
        snackbarSuccess(
          fr.status === 200 ? 'success' : 'warning',
          fr.status === 200
            ? 'Request submitted successfully'
            : response.error.message ||
                'Something went wrong while submit form, please try again !'
        )
      );
      return true;
    } catch (error) {
      console.log(error);
      snackbarSuccess(
        'warning',
        'Something went wrong while submit form, please try again !'
      );
      return true;
    }
  }

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        aria-labelledby="form-dialog-notes"
      >
        <DialogTitle id="form-dialog-notes"> NON-MEDICAL REQUEST</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Lorem lissum Lorem lissum Lorem lissum Lorem lissum Lorem lissum Lorem lissum Lorem lissum Lorem lissum Lorem lissum
          </DialogContentText> */}
          <div className="card-body">
            <form onSubmit={saveForm}>
              <div className="form-row">
                <div className="form-group">
                  <label>
                    Please provide details:{' '}
                    <span style={{color: 'red'}}>*</span>
                  </label>

                  <TextField
                    multiline
                    name="notes"
                    error={
                      isSubmitClicked &&
                      (formState.notes.value === '' ||
                        formState.notes.value.length < 3)
                    }
                    rowsMax={6}
                    value={formState.notes.value}
                    onChange={hasFormValueChanged}
                    id={`id_notes`}
                    placeholder="Further information about this request"
                    variant="outlined"
                  />

                  {isSubmitClicked &&
                    (formState.notes.value === '' ||
                      formState.notes.value.length < 3) && (
                      <div className="invalid-feedback">
                        {
                          'Please enter N/A or any further details necessary to fulfill your request.'
                        }
                      </div>
                    )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group request-food-btn">
                  <Button
                    type="submit"
                    className={`btn-b btn-primary btn-filled`}
                  >
                    Request
                  </Button>
                  <Button
                    className="btn-b btn-primary btn-outlined"
                    onClick={() => props.onClose()}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
