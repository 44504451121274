import {IActionBase} from '../models/root.interface';
import {IAccount} from '../models/account.interface';
import {LOG_OUT, PROFILE} from '../actions/account.actions';

const initialState: IAccount = {
  profile: ''
};

function accountReducer(
  state: IAccount = initialState,
  action: IActionBase
): IAccount {
  switch (action.type) {
    case LOG_OUT: {
      localStorage.removeItem('access_token');
      return {...state, profile: {}};
    }
    case PROFILE: {
      return {...state, profile: action?.profile};
    }
    default:
      return state;
  }
}

export default accountReducer;
