import React, {FormEvent, Dispatch} from 'react';
import {useDispatch} from 'react-redux';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  TextField
} from '@material-ui/core';
import {IPopupProps, ISupply} from '../../store/models/requests';
import {
  snackbarSuccess,
  postRequest,
  getQueueIdValue,
  updateLoader
} from '../../store/actions/root.actions';
import {apiPath} from '../../store/models/root.interface';
import isEmpty from 'lodash/isEmpty';

export default function FoodRequestModal(props: IPopupProps) {
  const dispatch: Dispatch<any> = useDispatch();
  const defaultFormVals = {notes: '', name: []};
  let inputForm: ISupply | null = defaultFormVals;
  const queues = props.queues || [];
  const [formState, setFormState] = React.useState({
    notes: {error: '', value: inputForm.notes},
    name: {error: '', value: inputForm.name}
  });
  const [isSubmitClicked, setSubmitClicked] = React.useState(false);
  function hasFormValueChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    setFormState({
      ...formState,
      [event.target.name]: {error: '', value: event.target.value}
    });
  }

  function isFormInvalid(): boolean {
    return (formState.notes.error ||
      formState.notes.value === '' ||
      formState.name.error ||
      formState.name.value.length < 1) as boolean;
  }

  async function saveForm(e: FormEvent<HTMLFormElement>): Promise<boolean> {
    e.preventDefault();
    setSubmitClicked(true);
    if (formState.name.value === '') {
      dispatch(
        snackbarSuccess('warning', 'Please select atleast one service type !')
      );
      return false;
    }
    if (isFormInvalid()) {
      dispatch(
        snackbarSuccess('warning', 'Please check all required fields !')
      );
      return false;
    }
    let name;
    let notes = formState.notes.value;
    if (formState.name.value.length > 1) {
      name = 'Multiple';
      // * auto add new lines
      notes = `${notes} ${
        isEmpty(formState.name.value) ? '' : '\n'
      }${formState.name.value.join('\n')}.`;
    } else {
      name = `${formState.name.value[0]}`;
    }
    dispatch(updateLoader(true));
    const queueUUId = getQueueIdValue(queues, 'Supply');
    let formInput: any = {};
    const roomUUID = props.userData.bed?.roomUUID || null;
    formInput = {
      notes: notes || '',
      name: `Supply : ${name}` || '',
      serviceQueueUUID: queueUUId,
      caseUUID: props.userData.uuid || null,
      status: 'Requested'
    };
    if (!props.userData.isOffCampus && roomUUID !== null) {
      formInput = {...formInput, roomUUID: roomUUID};
    }
    try {
      const fr = await postRequest(formInput, apiPath.postRequets, 'POST');
      const response = await fr.response.json();
      dispatch(updateLoader(false));
      props.onClose();
      dispatch(
        snackbarSuccess(
          fr.status === 200 ? 'success' : 'warning',
          fr.status === 200
            ? 'Request submitted successfully'
            : response.error.message ||
                'Something went wrong while submit form, please try again !'
        )
      );
      return true;
    } catch (error) {
      console.log(error);
      snackbarSuccess(
        'warning',
        'Something went wrong while submit form, please try again !'
      );
      return true;
    }
  }

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let arr = formState.name.value;
      arr.push(event.target.value);
      setFormState({...formState, name: {error: '', value: arr}});
    } else {
      let arr = formState.name.value;
      arr = arr.filter((val: any) => val !== event.target.value);
      setFormState({...formState, name: {error: '', value: [...arr]}});
    }
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Supply Request</DialogTitle>
        <DialogContent>
          <form onSubmit={saveForm}>
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <span className="mark-red">*</span>Request Supply:
                </FormLabel>
                <RadioGroup aria-label="quiz" name="quiz">
                  <FormControlLabel
                    value="Toilet paper"
                    control={<Checkbox onChange={handleCheckBoxChange} />}
                    label="Toilet paper"
                  />
                  <FormControlLabel
                    value="Laundry detergent"
                    control={<Checkbox onChange={handleCheckBoxChange} />}
                    label="Laundry detergent"
                  />
                  <FormControlLabel
                    value="Trash bags"
                    control={<Checkbox onChange={handleCheckBoxChange} />}
                    label="Trash bags"
                  />
                  <FormControlLabel
                    value="Bath soap"
                    control={<Checkbox onChange={handleCheckBoxChange} />}
                    label="Bath soap (hotel size)"
                  />
                  <FormControlLabel
                    value="Sick Pack"
                    control={<Checkbox onChange={handleCheckBoxChange} />}
                    label="Sick Pack: Gatorade, Ginger Ale, Crackers, Soup"
                  />
                  <FormControlLabel
                    value="Water"
                    control={<Checkbox onChange={handleCheckBoxChange} />}
                    label="Water"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>
                  Please provide details: <span style={{color: 'red'}}>*</span>
                </label>
                <TextField
                  multiline
                  name="notes"
                  error={
                    isSubmitClicked &&
                    (formState.notes.value === '' ||
                      formState.notes.value.length < 3)
                  }
                  rowsMax={6}
                  value={formState.notes.value}
                  onChange={hasFormValueChanged}
                  id={`id_notes`}
                  placeholder="Further information about this request"
                  variant="outlined"
                />

                {isSubmitClicked &&
                  (formState.notes.value === '' ||
                    formState.notes.value.length < 3) && (
                    <div className="invalid-feedback">
                      {
                        'Please enter N/A or any further details necessary to fulfill your request.'
                      }
                    </div>
                  )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group request-food-btn">
                <Button
                  type="submit"
                  className={`btn-b btn-primary btn-filled`}
                >
                  Request
                </Button>
                <Button
                  className="btn-b btn-primary btn-outlined"
                  onClick={() => props.onClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
