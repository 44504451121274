import React, {FormEvent, Dispatch} from 'react';
import {useDispatch} from 'react-redux';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl
} from '@material-ui/core';

import TextInput from '../../common/components/TextInput';
import {OnChangeModel} from '../../common/types/Form.types';
import {IPopupProps, ICheckoutRequest} from '../../store/models/requests';
import {
  snackbarSuccess,
  updateLoader,
  patchRequest
} from '../../store/actions/root.actions';
import {apiPath} from '../../store/models/root.interface';

export default function CheckoutModal(props: IPopupProps) {
  const dispatch: Dispatch<any> = useDispatch();
  const defaultFormVals = {notes: '', isTransportationNeeded: 'Yes'};
  let inputForm: ICheckoutRequest | null = defaultFormVals;

  const [formState, setFormState] = React.useState({
    notes: {error: '', value: inputForm.notes}
  });

  function hasFormValueChanged(model: OnChangeModel): void {
    setFormState({
      ...formState,
      [model.field]: {error: model.error, value: model.value}
    });
  }

  function isFormInvalid(): boolean {
    return (formState.notes.error || formState.notes.value === '') as boolean;
  }

  async function saveForm(e: FormEvent<HTMLFormElement>): Promise<boolean> {
    e.preventDefault();
    if (isFormInvalid()) {
      dispatch(
        snackbarSuccess('warning', 'Please check all required fields !')
      );
      return false;
    }
    dispatch(updateLoader(true));
    const formInput = {
      checkoutAddress: formState.notes.value || '',
      // roomUUID: props.userData.roomUUID || null,
      caseUUID: props.userData.uuid || null
      // checkedOutAt: moment.utc(),
      // status: "Checked Out"
    };
    const url = `${apiPath.checkout}`;
    const fr = await patchRequest(formInput, url, 'PATCH');
    dispatch(updateLoader(false));
    if (fr.status === 200) {
      dispatch(snackbarSuccess('success', 'Form submitted successfully'));
      props.onClose();
    } else {
      const response = await fr.response.json();
      dispatch(
        snackbarSuccess(
          'warning',
          response.error.message ||
            'Something went wrong while submit form, please try again !'
        )
      );
      props.onClose();
    }
    return true;
  }

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        aria-labelledby="form-dialog-notes"
      >
        <DialogTitle id="form-dialog-notes">CHECKOUT</DialogTitle>
        <DialogContent>
          <div className="card-body">
            <form onSubmit={saveForm}>
              <FormControl>
                <TextInput
                  id="input_notes"
                  value={formState.notes.value}
                  field="notes"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={50}
                  label="Address to where you are moving to ?"
                  disabled={false}
                  placeholder="Checkout Room Address"
                />
              </FormControl>
              <div className="form-row">
                <div className="form-group request-food-btn">
                  <Button
                    type="submit"
                    disabled={isFormInvalid()}
                    className={`btn-b btn-primary btn-filled`}
                  >
                    Submit
                  </Button>
                  <Button
                    className="btn-b btn-primary btn-outlined"
                    onClick={() => props.onClose()}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
