import axios from 'axios';

const setupAxios = (baseUrl: any, accessToken?: any) => {
  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Accept'] = 'application/json';

  if (!!accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }

  axios.interceptors.request.use(req => {
    // Important: request interceptors **must** return the request.
    return req;
  });

  axios.interceptors.response.use(
    res => {
      // Important: response interceptors **must** return the response.
      return res;
    },
    error => {
      // ! detects network errors and unauthorized errors
      // * reference https://github.com/axios/axios/issues/383#issuecomment-234079506
      if (!error.response || error?.response?.status === 401) {
        localStorage.removeItem('access_token');

        // * redirect it to our backend logout for saml logout
        window.location.href = `${process.env.REACT_APP_API_BASE_URL}/logout`;
      }
    }
  );
};

export {setupAxios};
