import React, {Fragment, useLayoutEffect} from 'react';
import Header from '../Header/Header';
import {Switch, Route, useHistory, useLocation} from 'react-router';
import Dashboard from '../Dashboard/Dashboard';
import {useDispatch, useSelector} from 'react-redux';
import {profileApi} from '../../common/helpers/profile';
import {selectProfile} from '../../store/reducers/root.reducer';

const Admin: React.FC = () => {
  // * if there is a token then get it from localstorage and put it in the root reducer
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const profile = useSelector(selectProfile);

  useLayoutEffect(() => {
    // * redirect to the proper -> route
    profileApi(dispatch, history, location, profile);
  }, [dispatch, history, location, profile]);

  return (
    <Fragment>
      <Header />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <div className="wrap">
            <Switch>
              <Route path="/">
                <Dashboard />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Admin;
