import React from 'react';
import Header from '../Header/Header';
import {Typography, Grid, Card, CardContent} from '@material-ui/core';

const Access: React.FC = () => {
  return (
    <div className="container">
      <Header />
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                variant="h5"
                component="h4"
                align="center"
              >
                Access Denied !
              </Typography>
              <Typography variant="h5" component="h4">
                Not Authorized: Access to this site is restricted to current
                participants in the Temporary Housing Program.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default Access;
