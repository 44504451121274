import {RootStateOrAny} from 'react-redux';
import {combineReducers, Reducer} from 'redux';
import {
  UPDATE_CURRENT_PATH,
  UPDATE_PAGINATION,
  UPDATE_LOADER,
  SNACKBAR_SUCCESS,
  SNACKBAR_CLEAR,
  GET_REQUESTS,
  GET_REQUESTS_COUNT,
  GET_SERVICE_QUEUE,
  GET_USER_DATA
} from '../actions/root.actions';
import {
  IRootStateType,
  IActionBase,
  IStateType
} from '../models/root.interface';
import accountReducer from './account.reducer';

const initialState: IRootStateType = {
  page: {area: 'home', subArea: ''},
  pagination: {totalPages: 0, currentPage: 1},
  loading: false,
  snackbar: {
    successSnackbarOpen: false,
    successSnackbarMessage: '',
    severity: ''
  },
  requests: [],
  queues: [],
  userData: {
    uuid: '',
    updatedAt: '',
    createdAt: '',
    isSelfCheckoutAuthorized: false,
    isOffCampus: false,
    individualUUID: '',
    roomUUID: '',
    foodPreferences: '',
    allergies: '',
    isautomated: false
  },
  account: {}
};

// TS infers type: (state: RootState) => boolean
export const selectProfile = (state: RootStateOrAny) => state?.account?.profile;

function rootReducer(
  state: IRootStateType = initialState,
  action: IActionBase
): IRootStateType {
  switch (action.type) {
    case UPDATE_CURRENT_PATH:
      return {...state, page: {area: action.area, subArea: action.subArea}};
    case UPDATE_PAGINATION:
      return {
        ...state,
        pagination: {
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage
        }
      };
    case UPDATE_LOADER:
      return {...state, loading: action.loading};
    case SNACKBAR_SUCCESS:
      return {
        ...state,
        snackbar: {
          successSnackbarOpen: true,
          successSnackbarMessage: action.successSnackbarMessage,
          severity: action.severity
        }
      };
    case SNACKBAR_CLEAR:
      return {
        ...state,
        snackbar: {
          successSnackbarOpen: false,
          successSnackbarMessage: '',
          severity: ''
        }
      };
    case GET_REQUESTS:
      return {...state, requests: action.data};
    case GET_REQUESTS_COUNT:
      return {
        ...state,
        pagination: {
          totalPages: action.data.totalPages,
          currentPage: action.data.currentPage
        }
      };
    case GET_SERVICE_QUEUE:
      return {...state, queues: action.data};
    case GET_USER_DATA:
      return {...state, userData: action.data};
    default:
      return state;
  }
}

const rootReducers: Reducer<IStateType> = combineReducers({
  root: rootReducer,
  account: accountReducer
});

export default rootReducers;
