import axios from 'axios';
import _ from 'lodash';
import {apiPath, settings, IQueue} from '../../store/models/root.interface';
import {logout} from '../../store/actions/account.actions';
// import { requestFormDefaultValues } from "../../store/models/requests";

import {IRootPagination, IRequest} from '../models/root.interface';
export const UPDATE_CURRENT_PATH: string = 'UPDATE_CURRENT_PATH';
export const UPDATE_PAGINATION: string = 'UPDATE_PAGINATION';
export const UPDATE_LOADER: string = 'UPDATE_LOADER';
export const SNACKBAR_SUCCESS: string = 'SNACKBAR_SUCCESS';
export const SNACKBAR_CLEAR: string = 'SNACKBAR_CLEAR';
export const GET_REQUESTS: string = 'GET_REQUESTS';
export const GET_REQUESTS_COUNT: string = 'GET_REQUESTS_COUNT';
export const GET_SERVICE_QUEUE: string = 'GET_SERVICE_QUEUE';
export const GET_USER_DATA: string = 'GET_USER_DATA';

export function updateCurrentPath(
  area: string,
  subArea: string
): IUpdateCurrentPathActionType {
  return {type: UPDATE_CURRENT_PATH, area: area, subArea: subArea};
}

export function snackbarSuccess(
  severity: string,
  successSnackbarMessage: string
): IUpdateSuccessSnackbarActionType {
  return {
    type: SNACKBAR_SUCCESS,
    severity: severity,
    successSnackbarMessage: successSnackbarMessage
  };
}

export function clearSnackbar() {
  return {type: SNACKBAR_CLEAR};
}

export function updateLoader(loading: boolean): IUpdateLoaderActionType {
  return {type: UPDATE_LOADER, loading: loading};
}

export const updatePaging = (
  totalPages: number,
  currentPage: number
): IUpdateCurrentPagination => ({
  type: UPDATE_PAGINATION,
  payload: {totalPages, currentPage}
});

function transformRequests(requests: IRequest[]) {
  return requests.map(request => {
    const isMealPackRequest =
      request.serviceQueue.name === 'Dining' &&
      request.foodSRType === '7 day Meal Pack';

    if (isMealPackRequest) {
      const [name, options] = (request.name ?? '7 day Meal Pack').split(': ');
      return {
        ...request,
        name,
        options: (options ?? 'N/A').split(',').filter(x => x)
      };
    }

    return request;
  });
}

export function getRequestList(
  currentPage: number,
  searchState: {name: string; status: string; sortBy: string; sortOrder: string}
) {
  return (dispatch: any) => {
    dispatch(updateLoader(true));
    const defaultPageSize = settings.defaultPageSize || 10;
    const skip = (currentPage - 1) * defaultPageSize;

    const orderBy = searchState.sortBy || 'createdAt';
    const orderAscDesc = searchState.sortOrder || 'DESC';

    let whereFilter = {};

    if (searchState.name !== 'All') {
      whereFilter = {...whereFilter, serviceQueueUUID: searchState.name};
    }
    if (searchState.status !== 'All') {
      whereFilter = {...whereFilter, status: searchState.status};
    }

    let filterData = {
      where: whereFilter,
      limit: defaultPageSize,
      skip: skip,
      order: `${orderBy} ${orderAscDesc}`,
      include: [{relation: 'serviceQueue'}]
    };

    let filter = JSON.stringify(filterData);

    axios({
      method: 'get',
      url: apiPath.requestList,
      headers: {
        Authorization: localStorage.getItem('access_token') ?? ''
      },
      params: {
        filter
      }
    })
      .then(res => {
        const responseData = (res.data && res.data.data) || [];
        const totalCount = (res.data && res.data.total) || 0;
        const totalPages = Math.ceil(totalCount / defaultPageSize);

        dispatch({
          data: transformRequests(responseData),
          type: GET_REQUESTS
        });

        dispatch({
          data: {totalPages: totalPages, currentPage: currentPage},
          type: GET_REQUESTS_COUNT
        });

        dispatch(updateLoader(false));
      })
      .catch(error => {
        console.log(error);
        if (error?.response?.status === 401) {
          dispatch(handleLogout());
        }
      });
  };
}

export function getRequestPageCount(currentPage: number) {
  return (dispatch: any) => {
    const defaultPageSize = settings.defaultPageSize || 10;

    axios({
      method: 'get',
      headers: {
        Authorization: localStorage.getItem('access_token') ?? ''
      },
      url: apiPath.requestCount
    })
      .then(res => {
        const totalCount = res.data.count || 0;
        const totalPages = Math.ceil(totalCount / defaultPageSize);
        dispatch({
          data: {totalPages: totalPages, currentPage: currentPage},
          type: GET_REQUESTS_COUNT
        });
      })
      .catch(error => console.log(error));
  };
}

export function getServiceQueueList() {
  return (dispatch: any) => {
    axios({
      method: 'get',
      headers: {
        Authorization: localStorage.getItem('access_token') ?? ''
      },
      url: apiPath.servieQueueList
    })
      .then(res => {
        dispatch({
          data: res.data,
          type: GET_SERVICE_QUEUE
        });
      })
      .catch(error => {
        console.log(error);
        if (error?.response?.status === 401) {
          dispatch(handleLogout());
        }
      });
  };
}

export function getIndividualData(emailAddress?: string) {
  return (dispatch: any) => {
    const filter = JSON.stringify({
      where: {
        'individual.emailAddress': emailAddress
      },
      include: [
        {relation: 'bed'},
        {
          relation: 'individual'
        },
        {relation: 'address'}
      ]
    });

    axios({
      method: 'get',
      url: apiPath.getloggedInUserData,
      headers: {
        Authorization: localStorage.getItem('access_token') ?? ''
      },
      params: {
        filter
      }
    })
      .then(res => {
        const userData = (res.data && res.data.data && res.data.data[0]) || {};
        dispatch({
          data: userData,
          type: GET_USER_DATA
        });
      })
      .catch(error => {
        console.log(error);
        if (error?.response?.status === 401) {
          dispatch(handleLogout());
        }
      });
  };
}

export const handleLogout = () => {
  return (dispatch: any) => {
    dispatch(logout());
    window.location.href = settings.logout_url;
  };
};

export async function postRequest(jsonObj = {}, path: string, method: string) {
  const url = process.env.REACT_APP_API_BASE_URL + path;
  const res = await fetch(url, {
    method: method,
    body: JSON.stringify(jsonObj),
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('access_token') ?? ''
    }
  });
  return {response: await res, status: await res.status};
}

export async function patchRequest(
  jsonObj = {},
  path: string,
  method: string = 'PATCH'
) {
  const url = process.env.REACT_APP_API_BASE_URL + path;
  const res = await fetch(url, {
    method: method,
    body: JSON.stringify(jsonObj),
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('access_token') ?? ''
    }
  });
  return {response: await res, status: await res.status};
}

export const getQueueIdValue = (queues: IQueue[], name: string) => {
  var queueData = _.find(queues, {name: name});
  const uuid: any = (queueData && queueData.uuid) || null;
  return uuid;
};

export const getRequestNameByNotes = (str: string) => {
  return (str && str.substring(0, 20)) || '';
};

interface IUpdateCurrentPathActionType {
  type: string;
  area: string;
  subArea: string;
}
interface IUpdateLoaderActionType {
  type: string;
  loading: boolean;
}
interface IUpdateCurrentPagination {
  type: string;
  payload: IRootPagination;
}
interface IUpdateSuccessSnackbarActionType {
  type: string;
  severity: string;
  successSnackbarMessage: string;
}
