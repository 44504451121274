import React, {useEffect} from 'react';
import logo from '../../images/logo.svg';
import {Button} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router';
import queryString from 'query-string';
import {useDispatch, useSelector} from 'react-redux';
import {profileApi} from '../../common/helpers/profile';
import {selectProfile} from '../../store/reducers/root.reducer';
import Header from '../Header/Header';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return queryString.parse(useLocation().search);
}

const Login: React.FC = () => {
  const query: any = useQuery();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const profile = useSelector(selectProfile);

  useEffect(() => {
    const {access_token} = query;

    // ? do this iff there is an access_token
    if (!!access_token) {
      // * store the token in the localstorage and in the root reducer
      localStorage.setItem('access_token', access_token);

      // * redirect to the proper -> route
      profileApi(dispatch, history, location, profile);
    }
  }, [query, dispatch, location, history, profile]);

  const loginClickHandler = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/login`;
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="d-sm-flex justify-content-center flex-column align-items-center">
          <div className="login-header t-align_center">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <h1 className="page-title">Temporary Resource Management (TRM)</h1>
          </div>
        </div>
        <hr className="m-t-lg m-b-lg" />
        <div className="t-align_center">
          <div className="description m-b">Click log in to get started.</div>
          <Button
            onClick={loginClickHandler}
            className="btn-b btn-primary btn-filled btn-lg btn-min"
          >
            Login
          </Button>
        </div>
      </div>
    </>
  );
};

export default Login;
