import {IQueue, IStudent} from './root.interface';

export interface IPopupProps {
  isOpen: boolean;
  onClose: any;
  queues?: IQueue[];
  userData: IStudent;
}
export interface ICleaning {
  notes: string;
}
export interface INonMedical {
  notes: string;
}
export interface ICheckoutRequest {
  notes: string;
  isTransportationNeeded: string;
}

export interface ISupply {
  notes: string;
  name: any;
}

export interface IFood {
  notes: string;
  name: string;
}

export const requestFormDefaultValues = {
  name: 'All',
  status: 'All',
  sortBy: 'createdAt',
  sortOrder: 'DESC'
};
