export const PROFILE: string = 'PROFILE';
export const LOG_OUT: string = 'LOG_OUT';

export function profile(profile?: any): IProfileActionType {
  return {type: PROFILE, profile};
}

export function logout(): ILogOutActionType {
  return {type: LOG_OUT};
}

interface IProfileActionType {
  type: string;
  profile?: any;
}

interface ILogOutActionType {
  type: string;
}
